import { baseURL } from "./BaseURL";
import router from "./router";
import * as axios from "axios";

export function InstallHttpInterceptors(Vue) {
	const session = Vue.prototype.$session;
	let token = "";

	axios.defaults.baseURL = baseURL;

	axios.interceptors.request.use(
		(config) => {
			if (session.exists() && session.has("token")) {
				token = session.get("token");
				config.headers.common["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response) => {
			return Promise.resolve(response);
		},
		(error) => {
			if (error !== null) {
				let response = error.response;

				if (
					response.status === 401 ||
					(response.status === 400 &&
						response.data.message &&
						response.data.message === "missing key in request header")
				) {
					session.destroy();
					router.push({ name: "Login" });
				}
			}

			return Promise.reject(error);
		}
	);
}
