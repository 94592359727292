import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSession from "vue-session";
import { InstallHttpInterceptors } from "./HttpInterceptor";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
	faUpload,
	faHome,
	faInfo,
	faAward,
	faCog,
	faBackspace,
} from "@fortawesome/free-solid-svg-icons";

Vue.config.productionTip = false;

library.add(faUpload, faHome, faInfo, faAward, faCog, faBackspace);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSession, { persist: true });

InstallHttpInterceptors(Vue);

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
