import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			title: "Login",
		},
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			title: "Home",
		},
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
		meta: {
			title: "About",
		},
	},
	{
		path: "/upload-raffles",
		name: "UploadRaffles",
		component: () =>
			import(
				/* webpackChunkName: "uploadraffles" */ "../views/UploadRaffles.vue"
			),
		meta: {
			title: "Upload Raffles",
		},
	},
	{
		path: "/winner",
		name: "Winner",
		props: true,
		component: () =>
			import(/* webpackChunkName: "winner" */ "../views/Winner.vue"),
		meta: {
			title: "Winner!",
		},
	},
	{
		path: "/upload-prizes",
		name: "UploadPrizes",
		component: () =>
			import(
				/* webpackChunkName: "uploadprizes" */ "../views/UploadPrizes.vue"
			),
		meta: {
			title: "Upload Prizes",
		},
	},
	{
		path: "/settings",
		name: "Settings",
		component: () =>
			import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
		meta: {
			title: "Settings",
		},
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} // Raffler`;
	return next();
});

export default router;
