<template>
	<svg class="barchart" :width="chartWidth + 80" :height="chartHeight + 40">
		<g transform="translate(35, 20)">
			<g
				class="x-axis"
				fill="none"
				:transform="`translate(0, ${chartHeight})`"
			>
				<path
					stroke="currentColor"
					:d="`M0.5,6V0.5H${chartWidth}.5V6`"
				></path>

				<g
					class="tick"
					opacity="1"
					font-size="10"
					font-family="sans-serif"
					text-anchor="middle"
					v-for="(bar, index) in bars"
					:key="index"
					:transform="`translate(${bar.x + bar.width / 2}, 0)`"
				>
					<line stroke="currentColor" y2="6"></line>
					<text fill="currentColor" y="9" dy="0.71em">
						{{ bar.xLabel }}
					</text>
				</g>
			</g>

			<g class="y-axis" fill="none" :transform="`translate(0, 0)`">
				<path
					class="domain"
					stroke="currentColor"
					:d="`M0.5,${chartHeight}.5H0.5V0.5H-6`"
				></path>
				<g
					class="tick"
					opacity="1"
					font-size="10"
					font-family="sans-serif"
					text-anchor="end"
					v-for="(tick, index) in yTicks"
					:key="index"
					:transform="`translate(0, ${y(tick) + 0.5})`"
				>
					<line stroke="currentColor" x2="-6"></line>
					<text fill="currentColor" x="-9" dy="0.32em">{{ tick }}</text>
				</g>
			</g>

			<g class="bars" fill="none">
				<g v-for="(bar, index) in bars" :key="index">
					<rect
						fill="rgb(244, 121, 32)"
						:key="index"
						:height="bar.height"
						:width="bar.width"
						:x="bar.x"
						:y="bar.y"
					></rect>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
import { scaleLinear, scaleBand } from "d3-scale";

export default {
	name: "BarChart",

	props: ["height", "width", "dataset"],

	computed: {
		yTicks() {
			return this.y.ticks(5);
		},

		x() {
			return scaleBand()
				.range([0, this.chartWidth])
				.padding(0.3)
				.domain(this.chartData.map((e) => e[0]));
		},

		y() {
			let values = this.chartData.map((e) => e[1]);
			return scaleLinear()
				.range([this.chartHeight, 0])
				.domain([0, Math.max(...values)]);
		},

		bars() {
			let bars = this.chartData.map((d) => {
				return {
					xLabel: d[0],
					x: this.x(d[0]),
					y: this.y(d[1]),
					width: this.x.bandwidth(),
					height: this.height - this.y(d[1]),
				};
			});

			return bars;
		},
	},

	data() {
		return {
			chartData: this.dataset,
			chartHeight: this.height,
			chartWidth: this.width,
		};
	},

	watch: {
		height(newValue) {
			this.chartHeight = newValue;
		},

		width(newValue) {
			this.chartWidth = newValue;
		},

		dataset(newValue) {
			this.chartData = newValue;
		},
	},
};
</script>
