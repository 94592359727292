<template>
	<div class="home">
		<h1>Random Raffler</h1>

		<div class="shim" v-if="selectingWinner">
			<p class="number">{{ countdownValue }}</p>
		</div>

		<p>{{ goalMessage }}</p>

		<button
			type="button"
			@click="onPickWinnerClick"
			v-if="unwon > 0 && stats.howManyNonWinners > 0"
		>
			Pick a Winner!
		</button>

		<button
			class="info-button floating-button"
			@click="goToAboutPage"
			alt="Go to the about page"
			title="Go to the about page"
		>
			<font-awesome-icon icon="info"></font-awesome-icon>
		</button>

		<button
			class="settings-button floating-button"
			@click="gotToSettingsPage"
			alt="Go to settings page"
			title="Go to settings page"
		>
			<font-awesome-icon icon="cog"></font-awesome-icon>
		</button>

		<BarChart
			width="400"
			height="400"
			:dataset="dataset"
			class="chart"
		></BarChart>
	</div>
</template>

<style scoped>
.home {
	height: 100%;
}

.settings-button {
	bottom: 110px;
}

.info-button {
	bottom: 180px;
}

.shim {
	background-color: grey;
	opacity: 0.8;
	width: 100%;
	height: 100%;
	z-index: 100;
	position: absolute;
	left: 0;
	top: 0;
}

.shim p.number {
	color: black;
	font-weight: 800;
	position: relative;
	top: 40%;
	font-size: 10em;
}

.chart {
	margin: 0 auto;
	width: 500px;
	position: relative;
	display: block;
	margin-top: 25px;
}
</style>

<script>
import * as axios from "axios";
import BarChart from "../components/BarChart.vue";

export default {
	name: "Home",

	components: {
		BarChart,
	},

	computed: {
		people() {
			return this.stats.numPayors > 1 ? "people" : "person";
		},

		goalMessage() {
      let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

			let diff = this.settings.monetaryGoal - this.stats.totalAmount;

			if (diff < 0) {
				return `${
					formatter.format(this.stats.totalAmount)
				} in raffles so far! You've exceeded your goal by ${Math.abs(
					formatter.format(diff)
				)}!`;
			} else {
				return `${formatter.format(this.stats.totalAmount)} in raffles so far! Only ${formatter.format(diff)} to go!`;
			}
		},
	},

	data() {
		return {
			stats: {
				numPayors: 0,
				totalAmount: 0.0,
				howManyNonWinners: 0,
			},
			selectingWinner: false,
			countdownInterval: null,
			countdownValue: 3,
			unwon: 0,
			settings: {
				monetaryGoal: 0.0,
			},
			dataset: [],
		};
	},

	async mounted() {
		this.stats = await this.getStats();
		this.unwon = await this.getCountUnwon();
		this.settings = await this.getSettings();

		this.dataset = [
			["Goal", this.settings.monetaryGoal],
			["Raised", this.stats.totalAmount],
		];
	},

	methods: {
		async getCountUnwon() {
			const response = await axios.get(`/prize/count/unwon`);
			let result = response.data.count;
			return result;
		},

		async getSettings() {
			const result = await axios.get(`/settings`);
			return result.data;
		},

		async getStats() {
			const result = await axios.get(`/stats`);
			return result.data;
		},

		async getWinner() {
			const result = await axios.get(`/pickwinner`);
			return result.data;
		},

		goToAboutPage() {
			this.$router.push({ name: "About" });
		},

		gotToSettingsPage() {
			this.$router.push({ name: "Settings" });
		},

		async onPickWinnerClick() {
			this.selectingWinner = true;
			this.countdownValue = 3;

			this.countdownInterval = setInterval(async () => {
				this.countdownValue--;

				if (this.countdownValue <= 0) {
					clearInterval(this.countdownInterval);

					let winner = await this.getWinner();
					this.$router.push({
						name: "Winner",
						params: { winner: winner },
					});
				}
			}, 1000);
		},
	},
};
</script>
