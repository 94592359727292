<template>
	<div class="log-form">
		<h2>Enter the password</h2>

		<form>
			<input
				type="password"
				v-model="password"
				class="password"
				ref="password"
			/>
			<button type="button" class="submit-button" @click="onSubmitClick">
				Submit
			</button>
		</form>
	</div>
</template>

<style>
.log-form {
	width: 40%;
	min-width: 320px;
	max-width: 475px;
	background: #fff;
	position: relative;
	margin: 1.5em auto;
}

.log-form form {
	display: block;
	width: 100%;
	padding: 2em;
}

.log-form h2 {
	color: #5d5d5d;
	font-size: 1.35em;
	display: block;
	background: #2a2a2a;
	width: 100%;
	text-transform: uppercase;
	padding: 0.75em 1em 0.75em 1.5em;
	box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.05);
	border: 1px solid #1d1d1d;
	margin: 0;
	font-weight: 200;
}

.log-form input {
	display: block;
	margin: auto auto;
	width: 100%;
	margin-bottom: 2em;
	padding: 0.5em 0;
	border: none;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 1.25em;
	color: #757575;
	font-size: 1em;
}

.log-form input:focus {
	outline: none;
}
</style>

<script>
import * as axios from "axios";

export default {
	name: "Login",

	data() {
		return {
			password: "",
		};
	},

	mounted() {
		this.$refs.password.focus();
	},

	methods: {
		async login() {
			const response = await axios.post(`/login`, {
				password: this.password,
			});

			return response.data;
		},

		async onSubmitClick() {
			try {
				let token = await this.login();
				console.log(token);

				this.$session.start();
				this.$session.set("token", token);

				this.$router.push({ name: "Home" });
			} catch (e) {
				console.log(e);
				alert("Inavlid login");
			}
		},
	},
};
</script>
